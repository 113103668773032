<template>
  <div class="table-wrap no-wrap-css">
    <div ref="table-scroll">
      <table class="tbl tbl-export">
        <thead>
          <tr>
            <th
              v-for="(header, idx) in tableHeaders"
              :key="`lineItemTableHeader_${idx}`"
              :class="header.class"
            >
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody class="body-half-screen">
          <div v-for="(itemData, idx) in tableRowData" :key="`line_item_row_${idx}`">
            <tr>
              <td class="expand_arrow" @click="handleExpandArrowClick(itemData.adready_id, idx)">
                <font-awesome-icon
                  :icon="['fa', itemData.open ? 'chevron-down' : 'chevron-right']"
                  class="pointer"
                />
              </td>
              <td class="cmpNameClass">{{ itemData.group_name }}</td>
              <td class="startClass">{{ itemData.st_dt }}</td>
              <td v-if="itemData.flip" class="selectedOptionClass">
                <k-checkbox
                  id="inLucidSelectGroup"
                  :checked="itemData.selectAll"
                  @change="handleSelectOptionClick(itemData.adready_id, idx, '', true)"
                />
              </td>
              <td class="inLucidClass disabled"></td>
            </tr>
            <div v-if="itemData.open">
              <tr
                v-for="(item, index) in itemData.line_items"
                :key="`expanded_line_item_${itemData.adready_id}_${index}`"
                :class="{ 'sub-row sub-row-column-highlight': !!itemData.open }"
              >
                <td class="expand_arrow"></td>
                <td class="cmpNameClass">{{ item.li_id }}</td>
                <td class="startClass"></td>
                <td v-if="itemData.flip" class="selectedOptionClass">
                  <k-checkbox
                    id="inLucidSelectGroup"
                    :checked="item.in_luc"
                    @change="handleSelectOptionClick(itemData.adready_id, idx, index, false)"
                  />
                </td>
                <td class="inLucidClass" style="font-size: 22px;">
                  {{ item.in_lucid_old ? '✓' : ' ' }}
                </td>
              </tr>
            </div>
          </div>
        </tbody>
      </table>
      <div v-if="tableRowData.length === 0" class="txt-align-center ttl">
        No data is available to show
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpcMapping',
  props: {
    tableHeaders: {
      required: true,
      default() {
        return [];
      },
      type: Array,
    },
    tableRowData: {
      required: true,
      default() {
        return [];
      },
      type: Array,
    },
  },

  methods: {
    handleExpandArrowClick(id, idx) {
      this.$emit('handleExpandRow', id, idx);
    },
    handleSelectOptionClick(id, idx, index, isSelectAll) {
      this.$emit('handleSelectoption', id, idx, index, isSelectAll);
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
@media screen {
  * {
    box-sizing: border-box;
  }
  .disabled {
    pointer-events: none;
  }
  table.tbl-export {
    thead {
      padding-bottom: unset;
      border-bottom: unset;
    }
    border-collapse: separate;
    td.short-column.sticky-column.subRowCollapsible {
      position: sticky;
      width: 26px !important;
      overflow: unset;
    }
    th {
      text-transform: uppercase;
    }
    td {
      padding: 2px;
    }
    // & .expand_arrow {
    //   width: 10px !important;
    // }
    .idClass {
      width: 40px !important;
    }
    .cmpNameClass {
      width: 50px !important;
      padding-left: 10px;
    }
    .startClass {
      width: 100px !important;
    }
    .selectedOptionClass {
      width: 30px !important;
      text-align: center;
    }
    .inLucidClass {
      width: 30px !important;
      text-align: center;
    }
    th.short-column.sticky-column-header.subRowCollapsible {
      position: sticky;
      width: 26px !important;
    }
  }
  table.tbl-export {
    position: sticky;
    z-index: 1;
    background-color: #212429;
    &.subRowCollapsible {
      left: 0px;
    }
    &-header {
      background: var(--primarycolor);
      z-index: 5;
      &.subRowCollapsible {
        left: 0px;
      }
    }
  }
  .table-wrap {
    padding-top: 20px;
    overflow: unset !important;
    .tbl {
      thead {
        z-index: 4;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  .body-half-screen {
    .relative {
      position: relative;
    }
    tr {
      position: relative;
      border-bottom: 1px solid #27292f;
    }
    tr td {
      // overflow: unset;
      // white-space: unset;
      // text-overflow: unset;
    }
    .sub-row-column-highlight {
      background-color: #191919;
      .cmpNameClass {
        padding-left: 20px;
      }
    }
    tr td.highlightBorder {
      border-right: 2px solid var(--primarycolor);
      position: absolute;
      left: 1px;
      z-index: 3;
      height: 3em;
    }
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}
::v-deep .spc-grouping table.tbl-export th.expand_arrow {
  width: 10px !important;
  padding-right: 0;
  padding-left: 0;
}

.pointer {
  cursor: pointer;
}
.chevron-arrow-border {
  position: absolute;
  width: 2px;
  height: 3em;
  background: var(--primarycolor);
  bottom: -10px;
  left: 16px;
}
.txt-align-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
  padding-top: 40px;
}
.ttl {
  display: inline-block;
  font-size: 0.875rem;
  color: #cad1d6;
  letter-spacing: 0.07em;
}
::v-deep .selectedOptionClass .row {
  display: block;
}
::v-deep .inLucidClass .row {
  display: block;
}
</style>

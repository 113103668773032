<template>
  <div class="relative">
    <k-nav-tabs
      class="nav-tabs"
      :menu="menuItems"
      :active="activeTab"
      @selected-menu="updateActiveMenu"
    />
    <div class="spc-grouping">
      <div class="sec">
        <b-loading-spinner v-if="isSpcGroupingLoading" class="txt-align-center ttl" />
        <spc-mapping
          v-if="!isSpcGroupingLoading"
          :table-headers="spcGroupingTableHeaders[activeTab]"
          :table-row-data="spcGroupingData[activeTab.toLowerCase()].data"
          @handleExpandRow="handleExpandRow"
          @handleSelectoption="handleSelectoption"
        />
        <k-button
          v-if="!isSpcGroupingLoading"
          :size="3"
          label="SUBMIT"
          class="update-spc-btn"
          @click="updateSpcMapping"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { inert } from 'adready-api/helpers/common';
import _ from 'underscore';
import { get } from 'vuex-pathify';
import spcGroupingApi from '~/api/spc-grouping-api';
import SpcMapping from '~/components/brandlift/spc-mapping.vue';

export default {
  name: 'SpcGroupingTable',
  components: {
    KNavTabs: () =>
      import(/* webpackChunkName: "k-nav-tabs" */ '~/components/elements/k-nav-tabs.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    SpcMapping,
  },
  props: {
    campaign: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
    advertiser: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      // keeping header seperate for grouping table header for different functionalities
      spcGroupingTableHeaders: {
        PLACEMENT: [
          { name: '', class: 'expand_arrow' },
          { name: 'AUDIENCE NAME', class: 'cmpNameClass' },
          { name: 'START DATE', class: 'startClass' },
          { name: 'SELECT', class: 'selectedOptionClass' },
          { name: 'IN LUCID', class: 'inLucidClass' },
        ],
        // PUBLISHER: [
        //   { name: '', class: 'expand_arrow' },
        //   { name: 'AUDIENCE TACTIC', class: 'cmpNameClass' },
        //   { name: 'START DATE', class: 'startClass' },
        //   { name: 'SELECTED', class: 'selectedOptionClass' },
        //   { name: 'IN LUCID', class: 'inLucidClass' },
        // ],
      },
      menuItems: [
        {
          name: 'Placement',
          to: '',
        },
        // {
        //   name: 'Publisher',
        //   to: '',
        // },
      ],
      activeTab: 'PLACEMENT',
      isSpcGroupingLoading: true,
    };
  },
  computed: {
    spcGroupingData: get('common/spcGroupingData'),
    account: get('common/account'),
  },

  watch: {
    campaign: {
      immediate: true,
      handler(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue)) {
          return;
        }
        this.fetchSpcGroupingData();
      },
    },
  },
  methods: {
    async fetchSpcGroupingData(forceReload = false) {
      this.isSpcGroupingLoading = true;
      const currentTabData = inert(this.spcGroupingData[`${this.activeTab.toLowerCase()}`]);

      if (
        forceReload ||
        !currentTabData?.success ||
        currentTabData?.campaign_id !== this?.campaign?.campaign_id
      ) {
        try {
          const response = await spcGroupingApi.lineItemWithSpcGrouping([
            this.advertiser ? this.advertiser.id : 0,
            this?.campaign?.campaign_id ? encodeURIComponent(this.campaign.campaign_id) : '',
            this.advertiser ? encodeURIComponent(this.advertiser.name) : '',
            this.account ? encodeURIComponent(this.account.name) : '',
            this.activeTab.toLowerCase(),
          ]);
          if (response?.data?.length > 0) {
            this.$store.dispatch(
              'common/setSpcGroupingData',
              inert({
                ...this.spcGroupingData,
                [`${this.activeTab.toLowerCase()}`]: {
                  ...response,
                  success: true,
                  campaign_id: this.campaign.campaign_id,
                  data: this.formatTableData(response?.data || []),
                },
              })
            );
          }
        } catch (err) {
          console.error('Error in fetching spc grouping ->', err.message, err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(
                `Error in fetching spc grouping [watch: payload] ->${err}`
              );
              err._reported = true;
            }
          }
        }
      }
      this.isSpcGroupingLoading = false;
    },
    async updateActiveMenu(tab = '') {
      tab = tab.toUpperCase();
      if (this.activeTab.toUpperCase() !== tab) {
        this.activeTab = tab;
        await this.fetchSpcGroupingData();
      }
    },
    async updateSpcMapping() {
      this.isSpcGroupingLoading = true;
      try {
        const lineItemData = this.spcGroupingData[this.activeTab.toLowerCase()].data || [];
        const submitPayload = [];
        for (let i = 0; i < lineItemData.length; i++) {
          const changedLineItems = [];
          const liItems = lineItemData[i].line_items || [];
          for (let j = 0; j < liItems?.length; j++) {
            if (liItems[j].in_luc) {
              changedLineItems.push(liItems[j]);
            }
          }
          if (changedLineItems.length > 0) {
            submitPayload.push({ ...lineItemData[i], line_items: changedLineItems });
          }
        }
        await spcGroupingApi.updateItemWithSpcGrouping(
          [
            this.advertiser ? this.advertiser.id : 0,
            this?.campaign?.campaign_id ? encodeURIComponent(this.campaign.campaign_id) : '',
            this.advertiser ? encodeURIComponent(this.advertiser.name) : '',
            this.account ? encodeURIComponent(this.account.name) : '',
            this.activeTab.toLowerCase(),
          ],
          submitPayload
        );
      } catch (err) {
        console.error('Error in updating spc grouping ->', err.message, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(
              `Error in updating spc grouping [watch: payload] ->${err}`
            );
            err._reported = true;
          }
        }
      } finally {
        this.fetchSpcGroupingData(true);
        this.isSpcGroupingLoading = false;
      }
    },
    formatTableData(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].open = false;
        data[i].selectAll = false;
        if (data[i]?.line_items?.length > 0) {
          for (let j = 0; j < data[i].line_items.length; j++) {
            data[i].line_items[j].in_lucid_old = data[i].line_items[j].in_luc;
            data[i].line_items[j].in_luc = false;
          }
        }
      }
      data.sort((first, second) => {
        if (first?.flip === second.flip) {
          return 0;
        }
        return first?.flip ? -1 : 1;
      });
      return data;
    },
    handleExpandRow(id, idx) {
      const selectedTab = this.activeTab.toLowerCase();
      const lineitemData = inert(this.spcGroupingData[selectedTab].data);
      lineitemData.map((item, index) => {
        if (item.adready_id === id && index === idx) {
          item.open = !item.open;
        }
        return item;
      });
      this.$store.dispatch('common/setSpcGroupingData', {
        ...this.spcGroupingData,
        [selectedTab]: { ...this.spcGroupingData[selectedTab], data: lineitemData },
      });
    },
    handleSelectoption(id, itemGroupIndex, itemRowIndex, isSelectAll) {
      const selectedTab = this.activeTab.toLowerCase();
      const lineitemData = inert(this.spcGroupingData[selectedTab].data);
      if (isSelectAll) {
        lineitemData.map((item, index) => {
          if (item.adready_id === id && index === itemGroupIndex) {
            item.selectAll = !item.selectAll;
            for (let i = 0; i < item.line_items.length; i++) {
              item.line_items[i].in_luc = item.selectAll;
            }
          }
          return item;
        });
      } else {
        lineitemData.map((item, index) => {
          if (item.adready_id === id && index === itemGroupIndex) {
            let isAllItemSelected = true;
            item.line_items[itemRowIndex].in_luc = !item.line_items[itemRowIndex].in_luc;
            for (let i = 0; i < item.line_items.length; i++) {
              if (!item.line_items[i].in_luc) {
                isAllItemSelected = false;
              }
            }
            item.selectAll = isAllItemSelected;
          }
          return item;
        });
      }
      this.$store.dispatch('common/setSpcGroupingData', {
        ...this.spcGroupingData,
        [selectedTab]: { ...this.spcGroupingData[selectedTab], data: lineitemData },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
.ttl {
  display: inline-block;
  font-size: 0.875rem;
  color: #cad1d6;
  letter-spacing: 0.07em;
}
.main-title {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
.nav-tabs {
  position: absolute;
  top: -33px;
  li {
    min-width: 70px;
    text-align: center;
    span {
      font-size: 13px;
      font-weight: 400;
    }
  }
}
::v-deep .nav-tabs .tab-active a {
  top: 0px !important;
}
.sec {
  position: relative;
  display: block;
  padding: 0rem 1rem 1rem 1rem;
  background-color: #212429;
  border-radius: 4px;
  min-height: 20rem;
  margin-top: 3.4rem;
  svg {
    cursor: pointer;
  }
}
.no-bg {
  background: transparent !important;
}
.qa-box {
  margin-top: 25px;
}
.relative {
  position: relative;
}
.txt-align-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
  padding-top: 40px;
  top: 60px;
}
::v-deep .spc-grouping .sec {
  min-height: 12rem;
}
::v-deep .spc-grouping .table-wrap th {
  width: 10px !important;
}
::v-deep table.tbl-export td.expand_arrow {
  width: 16px !important;
}
::v-deep .spc-grouping table.tbl-export .cmpNameClass {
  width: 300px !important;
  padding: 0 15px 0 0;
}
::v-deep .spc-grouping table.tbl-export .startClass {
  width: 100px !important;
}
::v-deep .spc-grouping table.tbl-export .selectedOptionClass {
  width: 80px !important;
}
::v-deep .spc-grouping table.tbl-export .inLucidClass {
  width: 80px !important;
}
::v-deep .spc-grouping .sub-row-column-highlight .cmpNameClass {
  padding-left: 15px !important;
}
::v-deep .spc-grouping .sub-row-column-highlight .selectedOptionClass {
  padding-left: 40px !important;
}
.update-spc-btn {
  position: relative;
  left: 930px;
  top: 6px;
}
</style>

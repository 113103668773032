import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const spcGroupingApi = new ApiCrud(http.AXIOS, {
  lineItemWithSpcGrouping:
    'GET advertisers/:id/brand_lift/campaign/:campaignId/spc_groupings_and_mappings?&advertiser=:advertiser&client=:client&type=:type',
  updateItemWithSpcGrouping:
    ' PUT advertisers/:id/brand_lift/campaign/:campaignId/spc_groupings_and_mappings?&advertiser=:advertiser&client=:client&type=:type',
});

export default spcGroupingApi;
